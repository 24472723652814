import { getBrandSales } from "src/api/dashboard/gallery";
import SaleBox from "../SaleBox";
import { useQuery } from "react-query";
import Loader from "src/modules/space/components/Loader";

const SalesSection = ({ brandId }) => {
  const { data: sales, isLoading } = useQuery({
    queryKey: ["galleryBrandSales", brandId],
    queryFn: () => getBrandSales({ brandId }),
  });
  return (
    <div className="sales-items">
      {isLoading ? (
        <Loader size={100} />
      ) : sales.length === 0 ? (
        <p
          style={{
            textAlign: "center",
            color: "var(----primary-color-dk)",
            fontSize: 10,
            marginTop: 20,
          }}
        >
          No sales made yet.
        </p>
      ) : (
        sales?.map(
          ({
            _id,
            customer,
            saleId,
            date,
            paymentTransactions,
            totalPaid,
            products,
            status,
          }) => (
            <SaleBox
              key={_id}
              _id={_id}
              customer={customer}
              saleId={saleId}
              date={date}
              items={products}
              paymentTransactions={paymentTransactions}
              total={totalPaid}
              status={status}
              brandId={brandId}
            />
          ),
        )
      )}
    </div>
  );
};

export default SalesSection;

import React, { useMemo } from "react";
import Lottie from "react-lottie";
import animationData from "src/assets/lotties/kiss-of-the-heart.json";
import loaderAnimationData from "src/assets/lotties/simple-loader.json";

export default function Loader({ size, type = "main" }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: type === "main" ? animationData : loaderAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const isMobile = useMemo(() => window.innerWidth <= 768, []);
  const adjustedSize = useMemo(
    () => (isMobile ? (type === "simple" ? 40 : 100) : size ?? 400),
    [isMobile, size, type],
  );

  return (
    <div className="d-flex align-items-center">
      <Lottie
        options={defaultOptions}
        width={type === "simple" ? 100 : adjustedSize}
        height={type === "simple" ? 40 : adjustedSize}
      />
    </div>
  );
}

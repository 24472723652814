import { useParams } from "react-router-dom";
import MainDashboardLayout from "../../layouts/MainDashboardLayout/index";
import ActionButton from "../../components/ActionButton";
import { MdEdit } from "react-icons/md";
// import "./index.scss";
import DataTable from "../../components/InfiniteDataTable";
import StatsNumberBox from "../../components/StatsNumberBox";
import { useQuery } from "react-query";
import { getEventDetails } from "src/api/space/events";
import Loader from "src/modules/space/components/Loader";
import dayjs from "dayjs";
import {
  listEventsCancelledTransactions,
  listEventsPendingTransactions,
  listEventTickets,
} from "src/api/dashboard/events";
import { FaEye, FaWhatsapp } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

import { useEffect, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { usePrompt } from "src/contexts/PromptContext";
import {
  cancelPaymentTransaction,
  confirmPaymentTransaction,
} from "src/api/dashboard/accounting";
import { notify, queryClient } from "src/utils";
import { GuardProvider } from "src/contexts/GuardContext";
import { getSpaceCustomers } from "src/api/dashboard/space";
import MarketingCampaignTable from "./components/MarketingCampaignTable";
import { getCampaignByEventId } from "src/api/dashboard/campaigns";
import { listExpenses } from "src/api/dashboard/accounting";
import CreateExpenseModal from "../../screens/Expenses/components/CreateExpenseModal";
import { paymentMethods } from "src/utils/constants";
import { IoIosLink } from "react-icons/io";

const EventTickets = () => {
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [selectedScreenshot, setSelectedScreenshot] = useState(null);
  const [isExpenseModalVisible, setIsExpenseModalVisible] = useState(false);

  const { eventId } = useParams();
  const { showPrompt, closePrompt } = usePrompt();

  const { data: event, isLoading } = useQuery(["event", eventId], () =>
    getEventDetails(eventId),
  );

  const { data: eventExpenses } = useQuery({
    queryKey: [["eventExpenses", event?._id]],
    queryFn: () =>
      listExpenses({
        category: "events",
        eventId: event?._id,
      }),
    enabled: !!event?._id,
  });

  return (
    <MainDashboardLayout
      slug={`Events / ${eventId}`}
      className="font-primary flex flex-col gap-6 lg:flex-row lg:gap-12"
    >
      <GuardProvider
        allowedUsers={["superAdmin", "receptionist", "eventManager"]}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-col gap-6 lg:flex-row">
            <div className="w-full lg:flex-1">
              <div className="flex items-start justify-between">
                <div className="section-label">Details</div>
                <ActionButton
                  className="rounded-full"
                  title={
                    <>
                      Copy url
                      <IoIosLink style={{ marginLeft: 5 }} />
                    </>
                  }
                  backgroundColor="colorPrimaryLight"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://auraspace.art/events/${eventId}/payment`,
                    );
                    notify("Url copied to clipboard", "success");
                  }}
                  style={{ paddingRight: 20, paddingLeft: 20 }}
                />
              </div>
              <div className="border-1 border-secondary-dark rounded-lg p-3">
                <div className="bg-primary-dark flex items-center justify-center rounded-[20px] px-3 py-1.5 text-white">
                  <p className="m-0">#{event?.eventId}</p>
                  <span className="mx-2 block h-[15px] w-[1px] rounded bg-white" />
                  <p className="m-0">{event?.price}L.E</p>
                  <span className="mx-2 block h-[15px] w-[1px] rounded bg-white" />
                  <p className="m-0">
                    {dayjs(event?.date).format("dddd, DD MMMM")}
                  </p>
                  <span className="mx-2 block h-[15px] w-[1px] rounded bg-white" />
                  <p className="m-0">{dayjs(event?.date).format("hh:mma")}</p>
                </div>
                <div className="mt-4">
                  <img
                    src={event?.imageUrl || "https://placehold.co/600x400"}
                    alt={event?.title}
                    className="h-auto w-full rounded-lg object-cover"
                  />
                </div>
                <h2 className="text-primary-light mb-2.5 mt-5 text-center text-lg leading-tight font-bold">
                  {event?.title}
                </h2>
                <p className="mb-2.5 text-xs text-center leading-relaxed">
                  {event?.description}
                </p>
                <GuardProvider
                  allowedUsers={["superAdmin", "eventManager"]}
                  isBlur={true}
                >
                  <ActionButton
                    className="px-5.5 py-0.75 ml-auto min-w-0 rounded-[20px]"
                    backgroundColor="colorPrimaryLight"
                    title={
                      <>
                        <span>Edit </span>
                        <MdEdit style={{ marginLeft: 5 }} />
                      </>
                    }
                  />
                </GuardProvider>
              </div>
              <br />
              <br />
              <br />
              <div className="section-label">Statistics</div>
              <div className="stats-boxes grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1">
                <StatsNumberBox
                  title="Sold Tickets"
                  mainNumber={
                    <GuardProvider
                      allowedUsers={["superAdmin", "eventManager"]}
                      isBlur={true}
                    >
                      {event.seats - event.remainingSeats + "/" + event.seats}
                    </GuardProvider>
                  }
                  numberIdentifier="ticket"
                />
                <StatsNumberBox
                  title="Total Sales"
                  mainNumber={
                    <GuardProvider
                      allowedUsers={["superAdmin", "eventManager"]}
                      isBlur={true}
                    >
                      {(event.seats - event.remainingSeats) * event.price}
                    </GuardProvider>
                  }
                  numberIdentifier="L.E"
                />
                <StatsNumberBox
                  title="Net Profit"
                  mainNumber={
                    <GuardProvider allowedUsers={["superAdmin"]} isBlur={true}>
                      {(event.seats - event.remainingSeats) * event.price -
                        (eventExpenses?.reduce(
                          (acc, exp) => acc + exp.amount,
                          0,
                        ) || 0)}
                    </GuardProvider>
                  }
                  numberIdentifier="L.E"
                />
              </div>
              <br />
              <br />
              <GuardProvider allowedUsers={["superAdmin"]} isBlur={true}>
                <div className="flex items-start justify-between gap-5">
                  <div className="section-label">Payments</div>
                  <ActionButton
                    onClick={() => setIsExpenseModalVisible(true)}
                    title="Issue Payment"
                    backgroundColor="colorPrimaryLight"
                    className="issue-payment-btn"
                  />
                </div>
                <div className="payments-box">
                  <br />
                  <DataTable
                    dataKey={["eventExpenses", event._id]}
                    fetchData={({ paginationToken, limit }) =>
                      listExpenses({
                        paginationToken,
                        limit,
                        category: "events",
                        eventId: event._id,
                      })
                    }
                    columns={[
                      {
                        title: "Title",
                        selector: (row) =>
                          row?.title
                            ?.replace("Events | " + event?.title, "")
                            ?.replace(" |", "")
                            ?.trim(),
                      },
                      {
                        title: "Amount",
                        selector: (row) => `${row?.amount} L.E`,
                      },
                      {
                        title: "Date",
                        selector: (row) =>
                          dayjs(row?.date).format("DD MMM YYYY"),
                      },
                    ]}
                  />
                </div>
              </GuardProvider>
              <br />
              <br />

              {/* {event && <MarketingCampaignTable event={event} />} */}
            </div>
            <div className="w-full overflow-hidden lg:flex-[2.5]">
              <div className="section-label">Tickets</div>
              <div className="overflow-x-auto">
                <DataTable
                  dataKey={["eventTickets", event._id]}
                  fetchData={() => listEventTickets(event._id)}
                  columns={[
                    { title: "Ticket Id", selector: (row) => row?.ticketId },
                    { title: "Name", selector: (row) => row?.customer?.name },
                    { title: "Phone", selector: (row) => row?.customer?.phone },
                    { title: "Email", selector: (row) => row?.customer?.email },
                    {
                      title: "Time",
                      selector: (row) => (
                        <>
                          <span style={{ fontSize: 8 }}>
                            {dayjs(row.createdAt).format("DD MMM")}
                          </span>{" "}
                          <span style={{ fontWeight: 500, fontSize: 10 }}>
                            {dayjs(row.createdAt).format("hh:mm a")}
                          </span>
                        </>
                      ),
                    },
                    { title: "Seats", selector: (row) => row?.ticketSeats },
                    {
                      title: "Payment Method",
                      selector: (row) => (
                        <div
                          className={`badge-${
                            Object.values(paymentMethods).indexOf(
                              row?.paymentTransaction?.paymentMethod,
                            ) + 1
                          }`}
                          onClick={() => {
                            if (
                              row?.paymentTransaction?.additionalInfo
                                ?.screenshotUrl
                            ) {
                              setSelectedScreenshot(
                                row?.paymentTransaction?.additionalInfo
                                  ?.screenshotUrl,
                              );
                              setIsImageViewerOpen(true);
                            }
                          }}
                        >
                          {row?.paymentTransaction?.paymentMethod}
                        </div>
                      ),
                    },
                  ]}
                  actions={[
                    {
                      component: (row) => (
                        <ActionButton
                          onClick={() => {
                            showPrompt({
                              title: "Refund Ticket",
                              message: `Are you sure you want to refund ${row?.paymentTransaction?.amount}L.E for ${row?.customer?.name}?`,
                              onConfirm: async () => {
                                // Cancel the ticket
                                await cancelPaymentTransaction(
                                  row?.paymentTransaction?._id,
                                );
                                // Invalidate queries in the page
                                await queryClient.invalidateQueries({
                                  queryKey: [["eventTickets", event._id]],
                                });
                                await queryClient.invalidateQueries({
                                  queryKey: [
                                    ["eventCancelledTransactions", event._id],
                                  ],
                                });
                                await queryClient.invalidateQueries({
                                  queryKey: ["event", eventId],
                                });

                                closePrompt();
                              },
                            });
                          }}
                          title="Refund"
                          backgroundColor="colorSecondaryDarker"
                          className="mr-2"
                        />
                      ),
                    },
                    {
                      component: (row) => (
                        <ActionButton
                          onClick={() => {
                            window.open(
                              `https://wa.me/${row?.customer?.phone.slice(1)}?text=${encodeURI(`Hi ${row?.customer?.name.split(" ")[0]}!
This is a message from Aura Space regarding your ticket purchase for the event: *${row?.event?.title}*

You can view your ticket from here:
https://auraspace.art/events/${row?.event?.eventId}/ticket-details/${row?.ticketId}

The event will be on ${dayjs(row?.event?.date).format("dddd, Do MMMM h:mma")}

Kindly know that you will be asked to show your ticket on the reception

Regards,
Mahmoud
Aura Space Team`)}`,
                              "_blank",
                            );
                          }}
                          title={<FaWhatsapp />}
                          backgroundColor="colorPrimaryLight"
                        />
                      ),
                    },
                  ]}
                />
                <br />
                <br />
                <div className="section-label">Unconfirmed Tickets</div>
                <DataTable
                  dataKey={["eventPendingTransactions", event._id]}
                  fetchData={() => listEventsPendingTransactions(event._id)}
                  columns={[
                    { title: "Name", selector: (row) => row?.customer?.name },
                    { title: "Phone", selector: (row) => row?.customer?.phone },
                    { title: "Email", selector: (row) => row?.customer?.email },
                    {
                      title: "Time",
                      selector: (row) => (
                        <>
                          <span style={{ fontSize: 8 }}>
                            {dayjs(row.createdAt).format("DD MMM")}
                          </span>{" "}
                          <span style={{ fontWeight: 500, fontSize: 10 }}>
                            {dayjs(row.createdAt).format("hh:mm a")}
                          </span>
                        </>
                      ),
                    },
                    {
                      title: "Seats",
                      selector: (row) => row?.additionalInfo?.ticketSeats,
                    },
                    {
                      title: "Payment Method",
                      selector: (row) => (
                        <div
                          className={`badge-${
                            Object.values(paymentMethods).indexOf(
                              row?.paymentMethod,
                            ) + 1
                          }`}
                        >
                          {row?.paymentMethod}
                        </div>
                      ),
                    },
                    {
                      title: "Screenshot",
                      selector: (row) =>
                        row?.additionalInfo?.screenshotUrl ? (
                          <ActionButton
                            onClick={() => {
                              setSelectedScreenshot(
                                row?.additionalInfo?.screenshotUrl,
                              );
                              setIsImageViewerOpen(true);
                            }}
                            title={
                              <div className="flex items-center justify-center gap-1">
                                <span>View</span>
                                <FaEye />
                              </div>
                            }
                            backgroundColor="colorPrimaryLight"
                          />
                        ) : (
                          "No Screenshot"
                        ),
                    },
                  ]}
                  actions={[
                    {
                      component: (row) => (
                        <ActionButton
                          onClick={() =>
                            showPrompt({
                              title: "Confirm Ticket",
                              message: `Are you sure that ${row?.customer?.name} paid ${row?.amount}L.E through ${row?.paymentMethod}?`,
                              onConfirm: async () => {
                                // Confirm the ticket
                                await confirmPaymentTransaction(row?._id);
                                // Invalidate queries in the page
                                await queryClient.invalidateQueries({
                                  queryKey: [["eventTickets", event._id]],
                                });
                                await queryClient.invalidateQueries({
                                  queryKey: [
                                    ["eventPendingTransactions", event._id],
                                  ],
                                });
                                await queryClient.invalidateQueries({
                                  queryKey: ["event", eventId],
                                });

                                closePrompt();
                              },
                            })
                          }
                          title="Confirm"
                          backgroundColor="colorPrimaryLight"
                          style={{ marginRight: 10 }}
                        />
                      ),
                    },
                    {
                      component: (row) => (
                        <ActionButton
                          onClick={() => {
                            showPrompt({
                              title: "Refund Ticket",
                              message: `Are you sure you want to reject ticket for ${row?.customer?.name}?`,
                              onConfirm: async () => {
                                // Cancel the transaction
                                await cancelPaymentTransaction(row?._id);
                                // Invalidate queries in the page
                                await queryClient.invalidateQueries({
                                  queryKey: [
                                    ["eventPendingTransactions", event._id],
                                  ],
                                });
                                await queryClient.invalidateQueries({
                                  queryKey: [
                                    ["eventCancelledTransactions", event._id],
                                  ],
                                });

                                closePrompt();
                              },
                            });
                          }}
                          title="Reject"
                          backgroundColor="colorSecondaryDarker"
                        />
                      ),
                    },
                  ]}
                />
                <br />
                <br />
                <div className="section-label">Cancelled/Refunded Tickets</div>
                <DataTable
                  dataKey={["eventCancelledTransactions", event._id]}
                  fetchData={() => listEventsCancelledTransactions(event._id)}
                  columns={[
                    { title: "Name", selector: (row) => row?.customer?.name },
                    { title: "Phone", selector: (row) => row?.customer?.phone },
                    { title: "Email", selector: (row) => row?.customer?.email },
                    {
                      title: "Time",
                      selector: (row) => (
                        <>
                          <span style={{ fontSize: 8 }}>
                            {dayjs(row.createdAt).format("DD MMM")}
                          </span>{" "}
                          <span style={{ fontWeight: 500, fontSize: 10 }}>
                            {dayjs(row.createdAt).format("hh:mm a")}
                          </span>
                        </>
                      ),
                    },
                    {
                      title: "Seats",
                      selector: (row) => row?.additionalInfo?.ticketSeats,
                    },
                    {
                      title: "Status",
                      selector: (row) =>
                        row?.ticket ? "Refunded" : "Cancelled",
                    },
                    {
                      title: "Payment Method",
                      selector: (row) => (
                        <div
                          className={`badge-${
                            Object.values(paymentMethods).indexOf(
                              row?.paymentMethod,
                            ) + 1
                          }`}
                        >
                          {row?.paymentMethod}
                        </div>
                      ),
                    },
                    {
                      title: "Screenshot",
                      selector: (row) =>
                        row?.additionalInfo?.screenshotUrl ? (
                          <ActionButton
                            onClick={() => {
                              setSelectedScreenshot(
                                row?.additionalInfo?.screenshotUrl,
                              );
                              setIsImageViewerOpen(true);
                            }}
                            title={
                              <div className="flex items-center justify-center gap-1">
                                <span>View</span>
                                <FaEye />
                              </div>
                            }
                            backgroundColor="colorPrimaryLight"
                          />
                        ) : (
                          "No Screenshot"
                        ),
                    },
                  ]}
                />
              </div>
            </div>

            <CreateExpenseModal
              isVisable={isExpenseModalVisible}
              setIsVisible={setIsExpenseModalVisible}
              eventId={event._id}
              title={`Events | ${event.title}`}
            />

            {isImageViewerOpen && (
              <ImageViewer
                src={[selectedScreenshot]}
                currentIndex={0}
                disableScroll
                closeOnClickOutside
                backgroundStyle={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                closeComponent={
                  <ActionButton
                    onClick={() => {
                      setIsImageViewerOpen(false);
                      setSelectedScreenshot(null);
                    }}
                    title={<IoClose />}
                    backgroundColor="colorSecondaryDarker"
                  />
                }
                onClose={() => {
                  setIsImageViewerOpen(false);
                  setSelectedScreenshot(null);
                }}
                className="max-h-[70vh] w-full rounded-lg object-contain"
              />
            )}
          </div>
        )}
      </GuardProvider>
    </MainDashboardLayout>
  );
};

export default EventTickets;

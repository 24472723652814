import React, { useEffect, useMemo, useState } from "react";
import { useInfiniteQuery } from "react-query";
import "./index.scss";
import InfinitScroll from "react-infinite-scroll-component";
import Lottie from "react-lottie";
import EditableCell from "./components/EditableCell";
import Loader from "src/modules/space/components/Loader";

const DataTable = ({
  dataKey,
  filters = {},
  fetchData,
  columns,
  actions = [],
  tabs = [],
  sort = (row1, row2) => 1,
  tableStyle = {},
  limit = parseInt((window.innerHeight / 20).toFixed(0)),
  onCountChange = () => null,
  isLimitedView = false,
}) => {
  const [activeTab, setActiveTab] = useState({});

  const { isLoading, data, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery(
      [dataKey, { ...filters, ...activeTab?.filters }],
      async ({ pageParam, queryKey, ...rest }) => {
        return fetchData({
          ...queryKey[1],
          paginationToken: pageParam,
          limit,
        });
      },
      {
        getNextPageParam: (lastPage) =>
          lastPage?.length < limit
            ? undefined
            : lastPage?.[lastPage?.length - 1]?._id,
      },
    );

  useEffect(() => {
    if (tabs?.length !== 0) {
      setActiveTab(tabs[0]);
    }
  }, [tabs]);

  useEffect(() => {
    console.log(activeTab?.filters);
  }, [activeTab]);

  const finalData = useMemo(
    () => data?.pages?.reduce((acc, curr) => [...acc, ...curr])?.sort(sort),
    [sort, data],
  );

  return (
    <>
      <div className="table-container">
        <div className="table-top-taps">
          {tabs?.map((tab) => (
            <button
              onClick={() => setActiveTab(tab)}
              className={
                (tab?.title === activeTab?.title ? "active " : "") + "top-tab"
              }
            >
              {tab.title}
            </button>
          ))}
        </div>
        <div
          className="table-wrapper"
          style={{ borderTopLeftRadius: tabs.length === 0 ? 6 : 0 }}
        >
          <InfinitScroll
            dataLength={finalData?.length || 0}
            next={fetchNextPage}
            hasMore={hasNextPage}
            scrollableTarget="table-wrapper"
            loader={isFetching && <Loader type="simple" />}
            endMessage={
              finalData?.length !== 0 && isLoading ? (
                <Loader type="simple" />
              ) : (
                finalData?.length === 0 &&
                !isLoading && (
                  <div className="loading-container">No Data Found</div>
                )
              )
            }
          >
            <table style={tableStyle}>
              <tr>
                {columns?.map(({ title }) => (
                  <th>{title}</th>
                ))}
                {!activeTab.disableActions && actions?.length !== 0 && (
                  <th>Actions</th>
                )}
              </tr>
              {finalData?.map((row, index) => (
                <tr key={index}>
                  {columns?.map(({ selector, title, editable }) =>
                    editable !== undefined ? (
                      <EditableCell {...editable(row)}>
                        {selector ? selector(row) : row[title.toLowerCase()]}
                      </EditableCell>
                    ) : (
                      <td>
                        {selector ? selector(row) : row[title.toLowerCase()]}
                      </td>
                    ),
                  )}
                  {!activeTab.disableActions && actions?.length !== 0 && (
                    <td className="actions-cell">
                      {actions?.map(({ component }) => component(row))}
                    </td>
                  )}
                </tr>
              ))}
            </table>
          </InfinitScroll>
        </div>
      </div>
    </>
  );
};
export default DataTable;

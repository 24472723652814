import React, { useState, useEffect } from "react";
import RightVectorWithTitle from "src/assets/images/right-vector-with-aura.png";
import LeftVectorWithTitle from "src/assets/images/Left-top-with-aura.png";
import selectPaymentMethods from "src/assets/images/select-payment-methods.png";

import "react-dropdown/style.css";
import "react-dropdown/style.css";
import "./index.scss";
import timeBg from "src/assets/images/count-bg.png";
import Countdown from "react-countdown";

import { notify } from "src/utils";
import { useParams } from "react-router-dom";
import { getEventDetails } from "src/api/space/events";

import EventModal from "../EventModal";
import Loader from "src/modules/space/components/Loader";
import SuccessEventModal from "./components/PaymentSuccessfulModal";
import dayjs from "dayjs";

const EventPayment = () => {
  const renderer = ({ hours, minutes, seconds, completed, days }) => {
    if (completed) {
      return <span>The Event Is Finished</span>;
    } else {
      return (
        <div className="countdown-container">
          <div className="item-holder"> {days}d </div> :
          <div className="item-holder">{hours}h </div>:
          <div className="item-holder">{minutes}m </div>:
          <div className="item-holder">{seconds}s</div>
        </div>
      );
    }
  };
  const [successBox, setSuccessBox] = useState(false);

  const { eventId } = useParams();
  const [selectedPaymentMethod, setselectedPaymentMethod] = useState("");
  const [eventDetails, setEventDetails] = useState();

  useEffect(() => {
    (async () => {
      const response = await getEventDetails(eventId);
      setEventDetails(response);
      if (response?.remainingSeats < 6) {
        setTicketsNumber(
          Array.from({ length: response?.remainingSeats }, (_, i) => i + 1),
        );
      }

      if (response?.remainingSeats <= 0) {
        notify("No tickets left for this event", "error");
      }
    })();
  }, []);

  const getPymentMethod = (event) => {
    setselectedPaymentMethod(event.target.value);
  };
  const [userTicketQuantity, setuserTicketQuantity] = useState(1);
  const [ticketsNumber, setTicketsNumber] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  ]);

  const getuserTicketQuantity = (event) => {
    setuserTicketQuantity(event.target.value);
  };
  const [totalPrice, setTotalPrice] = useState(250);
  useEffect(() => {
    setTotalPrice(
      (eventDetails?.priceAfterDiscount || eventDetails?.price) *
        userTicketQuantity,
    );
  }, [userTicketQuantity, eventDetails]);

  const [eventFormModal, setEventFormModal] = useState(false);

  const dropdownStyle = {
    backgroundColor: "#DDC7BA",
    border: "0 solid #E6D8C2",
    padding: "4px",
    borderRadius: "5px",
    width: "40px",
    outLine: "0",
    appearance: "auto",
  };
  return (
    <div>
      {eventDetails ? (
        <div>
          <div className="PaymentPage text-center">
            <div className="right-vector-with-aura">
              <img src={RightVectorWithTitle} alt="" />
            </div>
            <div className="left-vector-with-aura-payment">
              <img src={LeftVectorWithTitle} alt="" />
            </div>
            {successBox === false ? (
              <div>
                <div>
                  <div className="welcome">
                    <h1>Welcome to Aura Event Payment Gateway</h1>
                    <p>
                      Complete your ticket purchase securely and effortlessly.
                      <br />
                      Simply follow the steps below to secure your spot at our
                      event.
                    </p>
                    <h5 className="any-help">
                      If you need any help, please contact us at 01226568908
                    </h5>
                  </div>
                  <div>
                    <div className="event-desc m-auto p-4">
                      <div className="d-flex justify-content-between align-items-center big-title">
                        <div>
                          <h5>{eventDetails.title}</h5>
                        </div>
                        <div className="d-flex justify-content-end align-items-center avalible-seats-icon">
                          <h6 className="avalible-seats">
                            {dayjs(eventDetails.date).format("DD MMMM")}
                          </h6>
                          <div className="seat-count">
                            <img src={timeBg} alt="" />
                            <div className="avalible-seats-counter d-flex align-items-center justify-center">
                              <h6 className="me-2 whitespace-nowrap text-[16px]">
                                {dayjs(eventDetails.date).format("hh:mm A")}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="about-the-event">
                        <p className="">{eventDetails.description}</p>
                        <div className="image-timer-wrapper">
                          {eventDetails.imageUrl && (
                            <div className="img-event">
                              <img src={eventDetails.imageUrl} alt="" />
                            </div>
                          )}
                          <div className="countdown">
                            <Countdown
                              date={eventDetails.date}
                              renderer={renderer}
                            />
                          </div>
                        </div>
                      </div>
                      <hr className="separator-line" />
                      <div className="event-tickets-container mt-5">
                        <div className="selector-container">
                          <select
                            id="numberDropdown"
                            value={userTicketQuantity}
                            onChange={getuserTicketQuantity}
                            style={dropdownStyle}
                          >
                            {eventDetails?.remainingSeats < 10
                              ? ticketsNumber?.map((number) => (
                                  <option key={number} value={number}>
                                    {number}
                                  </option>
                                ))
                              : ticketsNumber.map((number) => (
                                  <option key={number} value={number}>
                                    {number}
                                  </option>
                                ))}
                          </select>
                          <h6 className=""> Event Ticket</h6>
                          <h6 className=" ">
                            <span className="">x</span>{" "}
                            {eventDetails?.priceAfterDiscount ||
                              eventDetails?.price}{" "}
                            L.E
                          </h6>
                        </div>
                        <div className="total-price">
                          <h5 className="me-md-5 total-word">Total</h5>
                          <h5 className="ms-5">
                            {userTicketQuantity *
                              (eventDetails?.priceAfterDiscount ||
                                eventDetails?.price)}
                            L.E
                          </h5>
                        </div>
                      </div>

                      <div>
                        <div className="select-payment-method-img">
                          <img src={selectPaymentMethods} alt="" />
                        </div>

                        <div className="m-auto">
                          <div className="payment-methods-container">
                            <div className="payment-method-radio">
                              <label className="radioLabel">
                                <input
                                  type="radio"
                                  name="customRadio"
                                  value="mobileWallet"
                                  checked={
                                    selectedPaymentMethod === "mobileWallet"
                                  }
                                  onChange={getPymentMethod}
                                />
                                Mobile Wallet
                              </label>
                            </div>
                            <div className="payment-method-radio">
                              <label className="radioLabel">
                                <input
                                  type="radio"
                                  name="customRadio"
                                  value="instaPay"
                                  checked={selectedPaymentMethod === "instaPay"}
                                  onChange={getPymentMethod}
                                />
                                InstaPay
                              </label>
                            </div>
                            <div className="payment-method-radio">
                              <label className="radioLabel">
                                <input
                                  type="radio"
                                  name="customRadio"
                                  value="telda"
                                  checked={selectedPaymentMethod === "telda"}
                                  onChange={getPymentMethod}
                                />
                                Telda
                              </label>
                            </div>
                            {/* <div className="payment-method-radio">
                               
                              <label className="radioLabel">
                                <input
                                  type="radio"
                                  name="customRadio"
                                  value="cash"
                                  checked={selectedPaymentMethod === "cash"}
                                  onChange={getPymentMethod}
                                />
                                Cash
                              </label>
                            </div> */}
                          </div>
                          <button
                            onClick={() => {
                              selectedPaymentMethod
                                ? setEventFormModal(true)
                                : notify(
                                    "Please Select Payment Methods",
                                    "error",
                                  );
                            }}
                            className="btn bay-btn mb-3"
                          >
                            Pay
                          </button>
                        </div>
                        <div className="wide-dash-line my-4"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="margin-top-mobile">
                <SuccessEventModal />
              </div>
            )}
            <div>
              <div>
                <EventModal
                  isvisible={eventFormModal}
                  setIsVisible={setEventFormModal}
                  userTicketQuantity={userTicketQuantity}
                  selectedPaymentMethod={selectedPaymentMethod}
                  totalPrice={totalPrice}
                  setSuccessBoxIsVisible={setSuccessBox}
                  eventId={eventId}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default EventPayment;

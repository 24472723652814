import ActionButton from "src/modules/dashboard/components/ActionButton";
import Input from "src/modules/dashboard/components/Input";
import ModalLayout from "src/modules/dashboard/components/ModalLayout";
import Select from "src/modules/dashboard/components/Select";
import { DatePicker } from "rsuite";
import { useState } from "react";
import "./index.scss";
import { expenseCategories } from "src/utils/constants";
import { createExpense } from "src/api/dashboard/accounting";
import { queryClient } from "src/utils";

const CreateExpenseModal = ({
  isVisable,
  setIsVisible,
  eventId,
  brandId,
  title,
}) => {
  const [expenseData, setExpenseData] = useState({
    title: title || "",
    amount: "",
    date: new Date(),
    category: eventId
      ? expenseCategories.EVENTS
      : brandId
        ? expenseCategories.GALLERY
        : "",
  });

  const resetForm = () => {
    setExpenseData({
      title: title || "",
      amount: "",
      date: new Date(),
      category: eventId
        ? expenseCategories.EVENTS
        : brandId
          ? expenseCategories.GALLERY
          : "",
    });
  };

  return (
    <>
      <ModalLayout
        show={isVisable}
        onAbort={() => {
          resetForm();
          setIsVisible(false);
        }}
        title={"Create New Expense"}
        className={"create-new-expense-modal"}
      >
        <Input
          placeholder={"Title"}
          type="text"
          value={expenseData.title}
          onChange={(e) =>
            setExpenseData({ ...expenseData, title: e.target.value })
          }
        />
        <Input
          placeholder={"Amount"}
          type="number"
          value={expenseData.amount}
          onChange={(e) =>
            setExpenseData({
              ...expenseData,
              amount: parseFloat(e.target.value),
            })
          }
        />
        <DatePicker
          format="dd/MM/yyyy"
          calendarDefaultDate={new Date()}
          cleanable={false}
          color="var(--primary-color)"
          onChange={(d) => setExpenseData({ ...expenseData, date: d })}
          defaultValue={expenseData.date}
          style={{ width: "100%", marginBottom: 15, marginTop: 5 }}
        />
        <Select
          value={expenseData.category}
          onChange={(c) => setExpenseData({ ...expenseData, category: c })}
          options={Object.entries(expenseCategories).map(([key, value]) => ({
            label: `${key
              .split("_")
              .map((c) => c[0].toUpperCase() + c.substr(1).toLowerCase())
              .join(" ")}`,
            value,
          }))}
          disabled={eventId || brandId}
          defaultTitle={"Select Category"}
        />
        <ActionButton
          title={"Create Expense"}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            fontSize: 16,
            fontFamily: "AoboshiOne",
            margin: "auto",
            marginTop: 30,
          }}
          backgroundColor="colorSecondaryDarker"
          onClick={async () => {
            if (await createExpense({ ...expenseData, eventId, brandId })) {
              if (eventId) {
                await queryClient.invalidateQueries({
                  queryKey: [["eventExpenses", eventId]],
                });
              } else if (brandId) {
                await queryClient.invalidateQueries({
                  queryKey: [["brandExpenses", brandId]],
                });
                await queryClient.invalidateQueries({
                  queryKey: ["galleryStatistics", brandId],
                });
              } else {
                await queryClient.invalidateQueries({
                  queryKey: ["expenses"],
                });
              }
              resetForm();

              setIsVisible(false);
            }
          }}
        />
      </ModalLayout>
    </>
  );
};

export default CreateExpenseModal;

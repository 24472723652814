import { createContext, useContext, useEffect, useState } from "react";
import { storageKeys } from "src/utils";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";

const GuardContext = createContext(0);

export const useGuard = () => useContext(GuardContext);

export const GuardProvider = ({
  children,
  allowedUsers,
  replaceWith,
  isBlur,
}) => {
  const [currentUserType, setCurrentUserType] = useState(undefined);
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    // Fetch user type from local storage
    const user = localStorage.getItem(storageKeys.USER);
    if (user) {
      setCurrentUserType(JSON.parse(user).userType);
    }
  }, []);

  useEffect(() => {
    if (currentUserType && allowedUsers) {
      setIsAllowed(allowedUsers.includes(currentUserType));
    }
  }, [currentUserType, allowedUsers]);

  const UnauthorizedMessage = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        color: "var(--secondary-color-dk2)",
        userSelect: "none",
      }}
    >
      <MdOutlineDoNotDisturbAlt size={100} />
      <h1>Unauthorized</h1>
      <p>You are not allowed to access this content</p>
    </div>
  );

  return (
    <GuardContext.Provider
      value={{
        allowedUsers,
        currentUserType,
      }}
    >
      {isAllowed ? (
        children
      ) : isBlur ? (
        <div
          style={{
            filter: "blur(8px)",
            pointerEvents: "none",
            userSelect: "none",
            position: "relative",
            display: "inline",
          }}
        >
          {children}
        </div>
      ) : replaceWith ? (
        replaceWith
      ) : (
        <UnauthorizedMessage />
      )}
    </GuardContext.Provider>
  );
};

import { executeWithAuth } from "src/api";
import { API_URL_DASHBOARD } from "src/utils";

export const listExpenses = ({ paginationToken, limit, ...filters }) =>
  executeWithAuth({
    method: "GET",
    url: `${API_URL_DASHBOARD}/accounting/expenses`,
    query: {
      paginationToken,
      ...(limit && { limit }),
      ...filters,
    },
  }).then((data) => data?.expenses || []);

export const createExpense = ({
  title,
  category,
  amount,
  date,
  eventId,
  brandId,
}) =>
  executeWithAuth({
    method: "POST",
    url: `${API_URL_DASHBOARD}/accounting/expense`,
    notifySuccessMsg: true,
    body: {
      title,
      category,
      amount,
      currency: "EGP",
      date,
      eventId,
      brandId,
    },
  }).then((data) => data?.expense);

export const confirmPaymentTransaction = (paymentTransactionId) =>
  executeWithAuth({
    method: "POST",
    notifySuccessMsg: true,
    url: `${API_URL_DASHBOARD}/accounting/transactions/${paymentTransactionId}/confirm`,
  }).then((data) => data?.paymentTransaction);

export const cancelPaymentTransaction = (paymentTransactionId) =>
  executeWithAuth({
    method: "POST",
    notifySuccessMsg: true,
    url: `${API_URL_DASHBOARD}/accounting/transactions/${paymentTransactionId}/cancel`,
  }).then((data) => data?.paymentTransaction);

import "./index.scss";
import { BiStats } from "react-icons/bi";

const StatsNumberBox = ({
  title,
  subTitle,
  mainNumber,
  numberIdentifier,
  comparedNumber,
  comparedText,
}) => {
  return (
    <div className="stats-number-box flex-1 w-full">
      {subTitle && (
        <div className="subTitle-container">
          <p>{subTitle}</p>
        </div>
      )}
      <h2 className="title">{title}</h2>
      <div className="numbers-container">
        {comparedNumber ? (
          <div className="compared-container">
            <BiStats
              size={20}
              color={
                comparedNumber < 0
                  ? "var(--secondary-color-dk2)"
                  : "var(--primary-color-dk)"
              }
            />

            <p
              style={{
                color:
                  comparedNumber < 0
                    ? "var(--secondary-color-dk2)"
                    : "var(--primary-color-dk)",
              }}
            >
              {comparedNumber < 0 ? "" : "+"}
              {comparedNumber} {comparedText}
            </p>
          </div>
        ) : (
          <div />
        )}
        <div className="main-number-container">
          {mainNumber}
          <span>{numberIdentifier}</span>
        </div>
      </div>
    </div>
  );
};

export default StatsNumberBox;

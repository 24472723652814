import { useCallback, useEffect, useMemo, useState } from "react";
import { MdEdit } from "react-icons/md";
import debounce from "lodash/debounce";
import "./index.scss";
import Input from "../../../Input";
import Select from "src/modules/dashboard/components/Select";
import { DatePicker } from "rsuite";

const EditableCell = ({
  children,
  currentValue,
  type,
  onEdit,
  selectOptions = [],
}) => {
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [value, setValue] = useState(currentValue);

  // In case if no action was taken
  useEffect(() => {
    setTimeout(() => {
      setValue(value);
    }, 40 * 1000);
  }, []);

  const onChange = useCallback(async () => {
    onEdit && (await onEdit(value));
    setIsEditModeOn(false);
  }, [onEdit, value]);

  const debounceHandleEdit = useMemo(
    () => debounce(onChange, type === "select" || type === "date" ? 0 : 1500),
    [onChange, type]
  );

  useEffect(() => {
    if (!isEditModeOn) return;
    switch (type) {
      case "number":
      case "text":
      case "select":
        if (value?.toString() !== currentValue?.toString()) {
          debounceHandleEdit();
        }
        break;
      case "date":
        if (
          new Date(value).toISOString() !== new Date(currentValue).toISOString()
        ) {
          debounceHandleEdit();
        }
        break;
      default:
    }

    return () => {
      debounceHandleEdit.cancel();
    };
  }, [currentValue, type, value]);

  useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);

  const renderEditComponent = () => {
    switch (type) {
      case "number":
      case "text":
        return (
          <Input
            value={value}
            type={type}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                debounceHandleEdit.cancel();
                onChange();
              }
            }}
            style={{
              border: "none",
              backgroundColor: "transparent",
              width: "max-content",
              height: "max-content",
              padding: 0,
              display: "block",
            }}
          />
        );

      case "select":
        return (
          <Select
            style={{ width: "max-content" }}
            defaultTitle="Select Period"
            isTitleDisabled
            value={value}
            options={selectOptions}
            onChange={setValue}
            defaultValue={value}
          />
        );
      case "date":
        return (
          <DatePicker
            format="dd/MM/yyyy HH:mm"
            calendarDefaultDate={new Date(value)}
            cleanable={false}
            color="var(--primary-color)"
            onChange={setValue}
            defaultValue={new Date(value)}
          />
        );
      default:
        return <button onClick={debounceHandleEdit}>Edit type invalid</button>;
    }
  };

  return (
    <td className="editable-cell">
      {!isEditModeOn && (
        <div className="edit-btn-hover" onClick={() => setIsEditModeOn(true)}>
          <MdEdit />
        </div>
      )}

      {isEditModeOn ? renderEditComponent() : children}
    </td>
  );
};

export default EditableCell;

import MainDashboardLayout from "src/modules/dashboard/layouts/MainDashboardLayout";
import { useMemo, useState } from "react";
import "./index.scss";
import { FaPlus } from "react-icons/fa";
import EventBox from "./components/EventBox";
import { listEvents } from "src/api/dashboard/events";
import { useInfiniteQuery, useQuery } from "react-query";
import Loader from "src/modules/space/components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import CreateEventModal from "./components/CreateEventModal/CreateEventModal";
import { GuardProvider } from "src/contexts/GuardContext";

const Events = () => {
  const [isCreateEventModalVisible, setIsCreateEventModalVisible] =
    useState(false);

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: "events",
      queryFn: ({ pageParam }) =>
        listEvents({ paginationToken: pageParam, limit: 10 }),
    });

  const events = useMemo(
    () => data?.pages?.reduce((acc, curr) => [...acc, ...curr])?.sort(),
    [data],
  );
  return (
    <MainDashboardLayout slug={"Events"} className="events-page">
      <GuardProvider
        allowedUsers={["superAdmin", "receptionist", "eventManager"]}
      >
        <div className="events-container flex flex-col gap-6 lg:flex-row">
          <div className="side-events">
            <button
              className="new-event-btn flex items-center justify-center gap-1"
              onClick={() => setIsCreateEventModalVisible(true)}
            >
              New Event <FaPlus className="white m-l-2" />
            </button>
          </div>
          <div className="events-list">
            {isLoading ? (
              <Loader />
            ) : (
              <div className="events-wrapper">
                <InfiniteScroll
                  dataLength={events?.length ?? 0}
                  next={fetchNextPage}
                  hasMore={hasNextPage}
                  scrollableTarget="events-wrapper"
                  loader={isFetching && <Loader type="simple" />}
                  endMessage={
                    events?.length !== 0 && isLoading ? (
                      <Loader type="simple" />
                    ) : (
                      events?.length === 0 &&
                      !isLoading && (
                        <div className="loading-container">No Data Found</div>
                      )
                    )
                  }
                >
                  {events?.map((event) => (
                    <EventBox key={event._id} {...event} />
                  ))}
                </InfiniteScroll>
              </div>
            )}
          </div>
        </div>
        <CreateEventModal
          isVisible={isCreateEventModalVisible}
          setIsVisible={setIsCreateEventModalVisible}
        />
      </GuardProvider>
    </MainDashboardLayout>
  );
};

export default Events;

import StatsNumberBox from "src/modules/dashboard/components/StatsNumberBox";
import "./index.scss";
import { useQuery } from "react-query";
import { getBrandStatistics } from "src/api/dashboard/gallery";
import { GuardProvider } from "src/contexts/GuardContext";

const StatisticsSection = ({ brandId }) => {
  const { data: statistics } = useQuery({
    queryKey: ["galleryStatistics", brandId],
    queryFn: () => getBrandStatistics(brandId),
  });
  return (
    <div className="stats-boxes-container">
      <StatsNumberBox
        title={"Sales"}
        subTitle={"This Month"}
        mainNumber={
          <GuardProvider allowedUsers={["superAdmin"]} isBlur={true}>
            {statistics?.thisMonthSales ?? 0}
          </GuardProvider>
        }
        numberIdentifier={"L.E"}
        comparedNumber={
          statistics?.thisMonthSales - statistics?.lastMonthSales ?? 0
        }
        comparedText={"last month"}
      />
      <StatsNumberBox
        title={"Sales"}
        subTitle={"Last Month"}
        mainNumber={
          <GuardProvider allowedUsers={["superAdmin"]} isBlur={true}>
            {statistics?.lastMonthSales ?? 0}
          </GuardProvider>
        }
        numberIdentifier={"L.E"}
        comparedNumber={
          statistics?.lastMonthSales - statistics?.beforeLastMonthSales ?? 0
        }
        comparedText={"month before"}
      />
      <StatsNumberBox
        title={"Current Stock"}
        subTitle={"All Time"}
        mainNumber={
          <GuardProvider allowedUsers={["superAdmin"]} isBlur={true}>
            {statistics?.currentStock ?? 0}
          </GuardProvider>
        }
        numberIdentifier={"L.E"}
      />
      <StatsNumberBox
        title={"Paid"}
        subTitle={"All Time"}
        mainNumber={
          <GuardProvider allowedUsers={["superAdmin"]} isBlur={true}>
            {statistics?.paidPayments?.reduce(
              (sum, payment) => sum + payment.amount,
              0,
            ) ?? 0}
          </GuardProvider>
        }
        numberIdentifier={"L.E"}
      />
      <StatsNumberBox
        title={"Current Balance"}
        subTitle={"All Time"}
        mainNumber={
          <GuardProvider allowedUsers={["superAdmin"]} isBlur={true}>
            {statistics?.currentBalance ?? 0}
          </GuardProvider>
        }
        numberIdentifier={"L.E"}
      />
    </div>
  );
};

export default StatisticsSection;

import { getSpaceCustomers, getWhatsAppDetails } from "src/api/dashboard/space";
import DataTable from "../../components/InfiniteDataTable";
import { default as MainDashboardLayout } from "../../layouts/MainDashboardLayout";
import dayjs from "dayjs";
import "./index.scss";
import { useQuery } from "react-query";
import Loader from "src/modules/space/components/Loader";
import QRCode from "react-qr-code";
import { GuardProvider } from "src/contexts/GuardContext";

const Customers = () => {
  const { data: WhatsAppDetails, isLoading: isLoadingWhatsAppDetails } =
    useQuery({
      queryKey: "WhatsAppDetails",
      queryFn: getWhatsAppDetails,
    });

  return (
    <>
      <MainDashboardLayout slug={"Customers"}>
        <GuardProvider allowedUsers={["superAdmin"]}>
          <div className="customers-page gap-6">
            <div className="w-full lg:flex-1">
              <div className="title-label">WhatsApp</div>
              <div className="whatsapp-details-container">
                {isLoadingWhatsAppDetails ? (
                  <Loader />
                ) : (
                  <>
                    <div className="connection-status-container">
                      <p>Status:</p>
                      {WhatsAppDetails?.WhatsAppConnectionStatus ===
                      "connected" ? (
                        <p style={{ color: "var(--primary-color)" }}>
                          Connected
                        </p>
                      ) : (
                        <p style={{ color: "var(--secondary-color-dk2)" }}>
                          Disconnected
                        </p>
                      )}
                    </div>
                    {WhatsAppDetails?.WhatsAppQrCode && (
                      <div className="qr-code-container">
                        <QRCode
                          value={WhatsAppDetails?.WhatsAppQrCode}
                          size={170}
                          bgColor="var(--secondary-color)"
                          fgColor="var(--primary-color-dk)"
                        />
                        {/* <img src="https://picsum.photos/300/300" alt="Qr Code" /> */}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="w-full overflow-hidden lg:flex-[3]">
              <div className="title-label">Customers</div>
              <DataTable
                fetchData={getSpaceCustomers}
                dataKey={["spaceCustomers"]}
                columns={[
                  { title: "#", selector: (row) => row.customerId },
                  { title: "Name" },
                  { title: "Phone" },
                  { title: "Email", selector: (row) => row?.email || "N/A" },
                  {
                    title: "Created At",
                    selector: (row) =>
                      dayjs(row?.createdAt).format("dd DD/MM/YYYY hh:mma"),
                  },
                ]}
              />
            </div>
          </div>
        </GuardProvider>
      </MainDashboardLayout>
    </>
  );
};

export default Customers;
